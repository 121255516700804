/* eslint-disable */
import React from "react"
import Layout from "../components/Layout"
import LoadRecaptcha from "../components/LoadRecaptcha"
import Calc from '../components/Home/Calc/Calc.js'
import styles from '../css/polish.module.css'
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Requestimator</title>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@R_I_V_E_T" />
      <meta name="twitter:creator" content="@designheretic" />
      <meta name="twitter:title" content="The best blockchain gateway on 🌎" />
      <meta name="twitter:description" content="Find out how much you could save with our Requestimator instantly—no strings attached." />
      <meta name="twitter:image" content="https://rivet.cloud/requestimator.png" />
    </Helmet>
    <LoadRecaptcha />
    <Calc />
    <li className={styles.signlink}><AniLink paintDrip to="/buidl" hex="#070707">Got 90 seconds? Follow this link to the world's easiest, fastest, no-BS signup—and try Rivet for yourself!</AniLink></li><br/>
  </Layout>
)
export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
